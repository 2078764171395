import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import SimpleReactLightbox from 'simple-react-lightbox'

import _ from 'lodash'

// CSS
import 'styles/HomePage.scss'

// Components
import {
  TitleDefault,
  TitleAlt,
  TitleSubDefault,
  ButtonDefault,
  ButtonAlt,
  ButtonLineDefault,
  ButtonLineAlt,
  BoxDefault,
  ContentDefault,
  BannerDefault,
} from 'components/Elements'
import Layout from 'components/Layout'
import Services from 'components/Services'
import Projects from 'components/Projects'
import SEO from 'components/SEO'
import VideoModal from 'components/VideoModal'
import PlanModal from 'components/PlanModal'

function HomePage({ data, location }) {
  const { wordpressPage: page } = data
  const { allWordpressCategory: categories } = data

  const selected = []
  let category = location.hash

  if (_.size(category) > 0) {
    category = category.replace('#', '')

    if (category) {
      const selectedCategory = _.find(
        categories,
        ({ node: { slug } }) => slug.toLowerCase() === category.toLowerCase()
      )

      if (_.size(selectedCategory) > 0) {
        selected.push(selectedCategory.node.wordpress_id)
      }
    }
  }

  const [filters, setFilters] = useState({
    tempSearch: '',
    search: '',
    selected,
  })

  if (_.size(selected) > 0) {
    filters.selected = selected
    window.location.hash = ''
  }
  return (
    <Layout>
      <SEO yoast={{ meta: page.yoast_meta }} />
      <div className="home-page">
        <BannerDefault className="vh-80 home">
          <div className="row h-100">
            <BackgroundImage
              className="banner-image"
              fluid={
                page.acf.header_group.banner_image.localFile.childImageSharp
                  .fluid
              }
            />
            <div className="col-lg-7 pb-4">
              <div className="rectangle">
                <div
                  dangerouslySetInnerHTML={{
                    __html: page.acf.header_group.banner_title,
                  }}
                />
                <div
                  className="subtitle"
                  dangerouslySetInnerHTML={{
                    __html: page.acf.header_group.banner_subtitle,
                  }}
                />
                <div className="buttonrow row">
                  <div className="col-lg-6">
                    <a
                      href="mailto:brian@koelewijn-crookewit.nl"
                      className="mb-4 button button-default "
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Solliciteer direct
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <ButtonLineDefault to="/vacatures#vacatures">
                      {page.acf.header_group.button_right_text}
                    </ButtonLineDefault>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BannerDefault>
        <div className="empty-space-100" />
        <section className="services-home">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <TitleDefault>
                  <span>{page.acf.home_services.titel_services}</span>
                  <br />
                  <strong>{page.acf.home_services.sub_title_services}</strong>
                </TitleDefault>
                <ContentDefault>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: page.acf.home_services.services_text,
                    }}
                  />
                </ContentDefault>
                <ButtonLineDefault to="over-ons" className="mb-5">
                  {page.acf.home_services.button_text_services}
                </ButtonLineDefault>
              </div>
              <div className="col-lg-8">
                <Services />
              </div>
            </div>
          </div>
        </section>
        <div className="empty-space-100" />
        <section className="onderhoudsplan">
          <div className="container">
            <div className="row position-relative">
              <Img
                className="position-absolute w-100 bgimage"
                fluid={page.acf.plan.image_plan.localFile.childImageSharp.fluid}
              />
              <div className="inner rectangle d-flex align-items-center justify-content-between">
                <div
                  dangerouslySetInnerHTML={{ __html: page.acf.plan.plan_text }}
                />
                <PlanModal>{page.acf.plan.button_text_plan}</PlanModal>
              </div>
            </div>
          </div>
        </section>
        <section className="projecten-home">
          <div className="container">
            <div className="row">
              <div className="w-100 empty-space-100" />
              <div className="col-12 d-flex p-0 title">
                <TitleDefault>
                  <span>{page.acf.projecten_home.title_projecten}</span>
                  <br />
                  <strong>{page.acf.projecten_home.subtitle_projecten}</strong>
                </TitleDefault>
                <ButtonLineDefault to="/projecten">
                  {page.acf.projecten_home.button_text_projecten}
                </ButtonLineDefault>
              </div>
              <div className="col-12">
                <div className="filters mt-4">
                  {_.map(
                    categories.edges,
                    ({ node: { wordpress_id: id, name } }) => {
                      const filterActive =
                        _.indexOf(filters.selected, id) !== -1

                      return (
                        <button
                          key={id}
                          className={`project-page-filters-label${
                            filterActive
                              ? ` project-page-filters-label-active`
                              : ``
                          }`}
                          type="button"
                          onClick={() => {
                            let newSelected = filters.selected
                            const index = _.indexOf(newSelected, id)

                            if (index === -1) newSelected.push(id)
                            else
                              newSelected = _.filter(
                                newSelected,
                                (select, newIndex) => newIndex !== index
                              )

                            return setFilters({
                              ...filters,
                              ...{ selected: newSelected },
                            })
                          }}
                        >
                          {name}
                        </button>
                      )
                    }
                  )}
                </div>

                <Projects categoryIds={filters.selected} limit={6} />
              </div>
            </div>
          </div>
          <div className="empty-space-100" />
        </section>
        <div className="empty-space-100" />

        <section className="werkenbij-home position-relative">
          <div className="werkenbij-home-image row position-absolute d-none d-lg-flex">
            <div className="werkenbij-home-image-container col-lg-6">
              <BackgroundImage
                fluid={
                  page.acf.work_at_home.image_work.localFile.childImageSharp
                    .fluid
                }
              />
            </div>
            <div className="col-12 col-lg-6" />
          </div>
          <div className="container">
            <div className="row py-4 py-lg-5">
              <div className="col-lg-6" />
              <div className="col-lg-6 py-lg-5 pb-4 pl-lg-4">
                <TitleDefault>
                  <span>{page.acf.work_at_home.title_work}</span>
                  <br />
                  <strong>{page.acf.work_at_home.subtitle_work}</strong>
                </TitleDefault>
                <ContentDefault>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: page.acf.work_at_home.content_work,
                    }}
                  />
                </ContentDefault>
                <div className="button-row mt-5">
                  <div className="mt-4 mt-sm-0">
                    <ButtonLineDefault to="/vacatures/">
                      {page.acf.work_at_home.button_right}
                    </ButtonLineDefault>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default HomePage

export const HomeQuery = graphql`
  query HomePageQuery {
    wordpressPage(wordpress_id: { eq: 6 }) {
      title
      acf {
        header_group {
          banner_title
          banner_subtitle
          button_left_text
          button_right_text
          banner_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1700, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        home_services {
          button_text_services
          services_text
          titel_services
          sub_title_services
        }
        plan {
          image_plan {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1240, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          plan_text
          button_text_plan
        }
        projecten_home {
          button_text_projecten
          subtitle_projecten
          title_projecten
        }
        work_at_home {
          button_left
          button_right
          content_work
          subtitle_work
          title_work
          image_work {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1280, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      yoast_meta {
        name
        content
        property
      }
    }
    allWordpressCategory(
      sort: { fields: name, order: DESC }
      filter: { wordpress_id: { nin: 9, ne: 2 } }
    ) {
      edges {
        node {
          slug
          name
          wordpress_id
        }
      }
    }
  }
`
